.feature-support-page {
    @import url('https://fonts.googleapis.com/css2?family=Mulish:wght@400;700&display=swap');

    // * {
    //     padding: 0;
    //     margin: 0;
    //     box-sizing: border-box;
    // }
    
    .support-body-container {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 100vh;
        margin: 0;
        font-family: "Mulish", sans-serif;
        font-weight: 400;
        background-color: #202035;
        text-rendering: optimizeLegibility;
        -webkit-tap-highlight-color: transparent;
        -webkit-font-smoothing: antialiased;
    }

    .feature-support-page {
        width: 100%;
    }
    
    .support-container {
        max-width: 100%;
        margin: 0 auto;
        padding: 20px;
    }

    .support-search {
        padding: 40px 50px;
        max-width: 700px;
        margin: 0 auto;
        border-radius: 5px;
        background-color: #1b1b30;
        text-align: center;
    }

    .support-search-title {
        margin: 0;
        color: #bbbbd7;
        font-weight: 400;
        font-size: 36px;
    }

    .support-search-form {
        position: relative;
        margin-top: 25px;
    }

    .support-search-input {
        width: 100%;
        padding: 25px 25px 25px 70px;
        border-radius: 5px;
        border: solid 1px #454564;
        background-color: #2a2a42;
        font-family: inherit;
        font-size: 18px;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.6);
    }

    .support-search-input:focus {
        outline: none;
        border-color: rgba(255, 255, 255, 0.8);
        transition: border-color 250ms;
    }

    .support-search-input::placeholder {
        color: inherit;
    }

    .support-search-icon {
        position: absolute;
        z-index: 2;
        left: 25px;
        top: 50%;
        width: 22px;
        height: 22px;
        margin-top: -11px;
        fill: rgba(255, 255, 255, 0.5);
    }

    .support-main {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-around;
        margin-top: 70px;
    }

    .support-menu {
        width: 280px;
        padding: 30px;
        margin-right: 30px;
        background-color: #1b1b30;
        border-radius: 10px;
    }

    .support-menu-title {
        font-weight: 700;
        font-size: 18px;
        color: #bbbbd7;
    }

    .support-menu-links {
        margin-top: 20px;
        padding-bottom: 25px;
        border-bottom: 2px solid #141428;
    }

    .support-menu-link {
        display: block;
        padding: 7px 0;
        color: #786cd5;
        font-size: 16px;
        text-decoration: none;
    }

    .support-menu-link:hover {
        color: #fff;
        transition: color 250ms;
    }

    .support-support {
        margin-top: 25px;
    }

    .support-support-title {
        color: #bbbbd7;
        font-size: 18px;
    }

    .support-support-text {
        margin-top: 15px;
        color: #bbbbd7;
        font-size: 16px;
        line-height: 22px;
    }

    .support-support-button {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        height: 56px;
        border-radius: 5px;
        background-color: #786cd5;
        font-size: 16px;
        font-weight: bold;
        text-decoration: none;
        color: #fff;
    }

    .support-support-content {
        width: 100%;
        max-width: 735px;
    }

    h1 {
        font-size: 2em;
        margin: .67em 0;
    }

    .support-content-item {
        position: relative;
        padding: 30px;
        margin-top: 10px;
        background-color: #1b1b30;
    }

    .support-content-title {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        color: #bbbbd7;
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
    }

    .support-badge {
        margin-left: 15px;
    }

    .support-content-description {
        max-width: 545px;
        margin-top: 20px;
        line-height: 22px;
        font-size: 16px;
        color: #bbbbd7;
    }

    .support-badge-primary {
        padding: 8px 18px;
        border-radius: 50px;
        font-size: 16px;
        font-weight: 400;
        color: #ff7e9c;
        background-color: rgba(252, 124, 154, 0.1);
    }

    .support-badge-secondary {
        padding: 8px 18px;
        border-radius: 50px;
        font-size: 16px;
        font-weight: 400;
        color: #7ea1ff;
        background-color: rgba(124, 152, 252, 0.1);
    }
}

@media (max-width: 992px) {
    .support-main {
        flex-direction: column;
        align-items: center;
    }

    .support-menu {
        width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
    }

    .support-content {
        width: 100%;
        max-width: 100%;
    }

    .support-search {
        padding: 20px;
        max-width: 100%;
    }

    .support-search-title {
        font-size: 28px;
    }

    .support-search-input {
        padding: 20px 20px 20px 50px;
        font-size: 16px;
    }

    .support-search-icon {
        width: 20px;
        height: 20px;
    }
}

.support-support-content {
    display: none;
}

.support-support-content.active {
    display: block;
}
