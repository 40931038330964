.feature-contact-sales {
    @import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600&display=swap");

    * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    outline: none;
    }

    * {
    font-family: Rubik, sans-serif;
    font-size: 17px;
    }

    *,
    :after,
    :before {
    box-sizing: border-box;
    }

    html {
    font-size: 16px;
    }

    @media screen and (max-width: 768px) {
    html {
        font-size: 15px;
    }
    }

    @media screen and (max-width: 465px) {
    html {
        font-size: 14px;
    }
    }

    body {
    background: #202020;
    }

    .contact {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100vw;
    min-height: 100vh;
    background: linear-gradient(
        180deg,
        #202020 0%,
        rgba(32, 32, 32, 0.2) 50%,
        #202020 100%
        ),
        url(https://images.unsplash.com/photo-1567359781514-3b964e2b04d6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1468&q=80);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    }

    .contact-sales-title {
    color: #cccccc;
    font-size: 2.5rem;
    }

    .contact-form {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: 1rem;
    border: 1px solid #602dff;
    border-radius: 1rem;
    background: #252525;
    max-width: 375px;
    width: 100%;
    }

    label {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    color: #ccc;
    }

    label input,
    label textarea {
    background: #202020;
    padding: 0.75rem;
    font-size: 1rem;
    border-radius: 0.5rem;
    color: #e5e5e5;
    border: 1px solid transparent;
    transition: all 0.25s ease 0s;
    }

    label textarea {
    max-width: 100%;
    max-height: 200px;
    min-width: 200px;
    min-height: 100px;
    }

    .send-btn {
    background: linear-gradient(90deg, #602dff 0%, #170055 100%);
    padding: 0.75rem;
    font-size: 1rem;
    font-weight: 600;
    color: #ccc;
    border-radius: 0.5rem;
    cursor: pointer;
    }

    label input:hover,
    label textarea:hover,
    label input:focus,
    label textarea:focus {
    border-color: #602dff;
    box-shadow: 0 0 0 3px #602dff80;
    }

    .send-btn:hover,
    .send-btn:focus {
    background: linear-gradient(90deg, #602dff 75%, #170055 100%);
    }

    @media screen and (max-width: 900px) {
    .contact {
        flex-direction: column;
        gap: 1rem;
    }
    .contact-title {
        text-align: center;
    }
    }

}