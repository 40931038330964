* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.features-container-main {
  height: 100vh;
  display: grid;
  place-items: center;
  overflow: hidden;
  margin-inline:40px;
}
main {
  position: relative;
  width: 100%;
  height: 100%;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3);
}

.features-item {
  width: 200px;
  height: 300px;
  list-style-type: none;
  position: absolute;
  top: 80%;
  transform: translateY(-50%);
  z-index: 1;
  background-position: center;
  background-size: cover;
  border-radius: 20px;
  box-shadow: 0 20px 30px rgba(255, 255, 255, 0.3) inset;
  transition: transform 0.1s, left 0.75s, top 0.75s, width 0.75s, height 0.75s;

  &:nth-child(1), &:nth-child(2) {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transform: none;
    border-radius: 0;
    box-shadow: none;
    opacity: 1;
  }

  &:nth-child(3) {
    left: 50%;
    transform: translateX(-50%);
  }

  &:nth-child(4) {
    left: calc(50% + 220px);
    transform: translateX(-50%);
  }

  &:nth-child(5) {
    left: calc(50% + 440px);
    transform: translateX(-50%);
  }

  &:nth-child(6) {
    left: calc(50% + 660px);
    transform: translateX(-50%);
    opacity: 0;
  }
}

.content {
  width: 30vw;
  max-width: 400px;
  position: absolute;
  top: 50%;
  left: 3rem;
  transform: translateY(-50%);
  font: 400 0.85rem helvetica, sans-serif;
  color: white;
  text-shadow: 0 3px 8px rgba(0, 0, 0, 0.5);
  opacity: 0;
  display: none;

  .title {
    font-family: 'arial-black';
    text-transform: uppercase;
    color: #fff;  
  }

  .description {
    line-height: 1.7;
    margin: 1rem 0 1.5rem;
    font-size: 0.8rem;
  }

  button {
    width: fit-content;
    background-color: rgba(0, 0, 0, 0.1);
    color: white;
    border: 2px solid white;
    border-radius: 0.25rem;
    padding: 0.75rem;
    cursor: pointer;
  }
}

.features-item:nth-of-type(2) .content {
  display: block;
  animation: show 0.25s ease-in-out 0.3s forwards;
}

@keyframes show {
  0% {
    filter: blur(5px);
    transform: translateY(calc(-50% + 75px));
  }
  100% {
    opacity: 1;
    filter: blur(0);
  }
}

.nav {
  position: absolute;
  bottom: 2rem;
  left: 20%;
  transform: translateX(-50%);
  z-index: 5;
  user-select: none;

  .btn {
    background-color: rgba(255, 255, 255, 0.5);
    color: rgba(0, 0, 0, 0.7);
    border: 2px solid rgba(0, 0, 0, 0.6);
    margin: 0 0.25rem;
    padding: 0.75rem;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
      background-color: rgba(255, 255, 255, 0.3);
    }
  }
}

/* Adjust styles for screens between 650px and 900px */
@media (min-width: 650px) and (max-width: 992px) {
  .content {
    .title {
      font-size: 1rem;
    }
    .description {
      font-size: 0.7rem;
    }
    button {
      font-size: 0.7rem;
    }
  }

  .features-item {
    width: 250px;  /* Adjusted width for smaller screens */
    height: 300px; /* Adjusted height for smaller screens */
    list-style-type: none;
    position: absolute;
    top: 80%;
    transform: translateY(-50%);
    z-index: 1;
    background-position: center;
    background-size: cover;
    border-radius: 20px;
    box-shadow: 0 20px 30px rgba(255, 255, 255, 0.3) inset;
    transition: transform 0.1s, left 0.75s, top 0.75s, width 0.75s, height 0.75s;

    &:nth-child(3) {
      left: 50%;
      transform: translateX(-50%);
    }

    &:nth-child(4) {
      left: calc(50% + 170px);
      transform: translateX(-50%);
    }

    &:nth-child(5) {
      left: calc(50% + 340px);
      transform: translateX(-50%);
    }

    &:nth-child(6) {
      left: calc(50% + 510px);
      transform: translateX(-50%);
      opacity: 0;
    }
  }
}

/* Adjust styles for screens up to 992px */
@media (max-width: 992px) {
  .features-item {
    width: 250px;  /* Adjusted width for smaller screens */
    height: 300px; /* Adjusted height for smaller screens */
    list-style-type: none;
    position: absolute;
    top: 80%;
    transform: translateY(-50%);
    z-index: 1;
    background-position: center;
    background-size: cover;
    border-radius: 20px;
    box-shadow: 0 20px 30px rgba(255, 255, 255, 0.3) inset;
    transition: transform 0.1s, left 0.75s, top 0.75s, width 0.75s, height 0.75s;

    &:nth-child(3) {
      left: 50%;
      transform: translateX(-50%);
    }

    &:nth-child(4) {
      left: calc(50% + 170px);
      transform: translateX(-50%);
    }

    &:nth-child(5) {
      left: calc(50% + 340px);
      transform: translateX(-50%);
    }

    &:nth-child(6) {
      left: calc(50% + 510px);
      transform: translateX(-50%);
      opacity: 0;
    }
  }

  .content {
    width: 30vw;
    max-width: 400px;
    position: absolute;
    top: 50%;
    left: 3rem;
    transform: translateY(-50%);
    font: 400 0.85rem helvetica, sans-serif;
    color: white;
    text-shadow: 0 3px 8px rgba(0, 0, 0, 0.5);
    opacity: 0;
    display: none;

    .title {
      font-size: 0.9rem;
    }
    .description {
      font-size: 0.65rem;
    }
    button {
      font-size: 0.7rem;
    }
    
  }

  .features-item {
    width: 130px;
    height: 220px;
    list-style-type: none;
    position: absolute;
    top: 68%;
    transform: translateY(-50%);
    z-index: 1;
    background-position: center;
    background-size: cover;
    border-radius: 20px;
    box-shadow: 0 20px 30px rgba(255, 255, 255, 0.3) inset;
    transition: transform 0.1s, left 0.75s, top 0.75s, width 0.75s, height 0.75s;

    &:nth-child(3) {
      left: 55%;
      transform: translateX(-50%);
    }

    &:nth-child(4) {
      left: calc(55% + 140px);
      transform: translateX(-50%);
    }

    &:nth-child(5) {
      left: calc(58% + 280px);
      transform: translateX(-50%);
    }

    &:nth-child(6) {
      left: calc(50% + 420px);
      transform: translateX(-50%);
      opacity: 0;
    }
  }
}

/* Adjust styles for screens up to 650px (mobile screens) */
@media (max-width: 650px) {
  .content {
    .title {
      font-size: 0.8rem; /* Adjusted for mobile */
    }
    .description {
      font-size: 0.6rem; /* Adjusted for mobile */
    }
    button {
      font-size: 0.6rem; /* Adjusted for mobile */
    }
  }

  .features-item {
    width: 100px; /* Adjusted for mobile */
    height: 150px; /* Adjusted for mobile */

    &:nth-child(3) {
      left: 55%;
      transform: translateX(-50%);
    }

    &:nth-child(4) {
      left: calc(55% + 110px);
      transform: translateX(-50%);
    }

    &:nth-child(5) {
      left: calc(58% + 220px);
      transform: translateX(-50%);
    }

    &:nth-child(6) {
      left: calc(50% + 330px);
      transform: translateX(-50%);
      opacity: 0;
    }
  }
}

