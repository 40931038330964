.team-item{
	overflow: hidden;
}
.team-overlay-content {
	position: absolute;
	width: 70%;
	left: 0px;
	bottom: 40px;
	h5{
		transform: translateX(-40px);
		opacity: 0;
		transition: .2s ease;
		transition-delay: .0s;
		position: relative;
		z-index: 1;
	}
	p{
		color: rgba(255, 255, 255, .7);
		transform: translateX(-16px);
		opacity: 0;
		transition: .2s ease;
		transition-delay: .0s;
		position: relative;
		z-index: 1;
	}

	&::before {
		position: absolute;
		content: "";
		height: 100%;
		width: 100%;
		background: $secondary-color;
		left: 0;
		top: 0;
		transform: scaleX(0);
		transform-origin: left;
		transition: .2s ease-in;
	}

	&::after {
		position: absolute;
		content: "";
		height: 100%;
		width: 100%;
		background: $primary-color;
		left: 0;
		top: 0;
		transform: scaleX(0);
		transform-origin: left;
		transition: .2s ease-in;
	}
}

.team-item:hover{
	.team-overlay-content{
		&::before{
      transform: scaleX(1);
    }
    &::after{
      transform: scaleX(1);
      transition-delay: .3s;
		}
		h5{
      transform: translateX(0);
      opacity: 1;
      transition-delay: .4s;
    }
    p{
      transform: translateX(0);
      opacity: 1;
      transition-delay: .5s;
    }
	}
}