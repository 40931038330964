.feature-pricing-page {
    @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");

    *{
        margin: 0;
        padding: 0;
        font-family: "Poppins", sans-serif;
        font-size: 13px;
    }

    *{
        --pinkish-red: #C35A74;
        --medium-blue: #307BAA;
        --greenish-blue: #53BAB5;
        --bright-orange: #FF7445;
        --white-smoke: #F5F5F4;
        --white: #FFF;
        --dark-gray: #7D7C7C;
        --black: #000;
    }

    .pricing-section{
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 100vh;
        //margin: auto;
        width: 100%;

        background: var(--white-smoke);
    }

    .pricing-content{
        display: flex;
        justify-content: space-around;
        width: 1200px;
        margin: 100px;
    }

    .pricing-box{
        display: flex;
        flex-direction: column;
        height: 586px;
        width: 300px;
        border-radius: 20px;
        margin-left: 10px;
        margin-right: 10px;
        
        background: var(--white);
        box-shadow: 0 1rem 2rem rgba(0, 0, 0, 20%);
    }

    .pricing-title{
        width: 100%;
        padding: 10px 0;
        font-size: 1.2em;
        font-weight: lighter;
        text-align: center;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;

        color: var(--white-smoke);
    }

    .basic .pricing-title{
        background: var(--pinkish-red);
    }

    .standard .pricing-title{
        background: var(--medium-blue);
    }

    .business .pricing-title{
        background: var(--greenish-blue);
    }

    .pricing-view{
        display: block;
        width: 100%;
        padding: 30px 0 20px;

        background: var(--white-smoke);
    }

    .pricing-icon{
        display: flex;
        justify-content: center;
    }

    .pricing-icon img{
        width: 100px;
    }

    .pricing-cost{
        display: flex;
        justify-content:center;
        flex-direction: row;
        margin-top: 10px;
    }

    .pricing-amount{
        font-size: 2.8em;
        font-weight: bolder;
    }

    .pricing-detail{
        margin: auto 0 auto 5px;
        width: 80px;
        font-size: 1em;
        font-weight: bold;
        line-height: 15px;
        color: #7D7C7C;
    }

    .pricing-description{
        margin: 30px auto;
        font-size: 0.8em;
        color: #7D7C7C;
    }

    ul{
        list-style: none;
    }

    li{
        margin-top: 10px;
    }

    li::before{
        content: "";
        background-image: url("https://i.postimg.cc/ht7g996V/check.png");
        background-position: center;
        background-size: cover;
        opacity: 0.5;

        display: inline-block;
        width: 10px;
        height: 10px;
        margin-right: 10px;
    }

    .pricing-button{
        margin: 0 auto 30px;
    }

    #basic-button, #standard-button, #business-button {
        height: 40px;
        width: 250px;
        font-size: 1em;
        font-weight: bold;
        letter-spacing: 0.5px;
        color: #7D7C7C;
        border: 2px solid var(--dark-gray);
        border-radius: 50px;
        text-align: center;
        background: transparent;
    }

    #basic-button:hover, #standard-button:hover, #business-button:hover {
        color: var(--white-smoke);
        transition: 0.5s;
        border: none;

        background: var(--bright-orange); 
    }

    /* Responsiveness:Start */
    @media screen and (max-width:970px) {
        .pricing-content{
            display: flex;
            align-items: center;
            flex-direction: column;
            margin: 50px auto;
        }
        .standard, .business{
            margin-top: 25px;
        }
    }
    /* Responsiveness:End */
}

.feature-comparison {
    @import url('');

    tr.accordion-header {
        cursor: pointer;
      }
      .accordion-header .sign:before {
        content: "+";
        display: inline-block;
        margin-right: 20px;
      }
      .accordion-header.expand .sign:before {
        content: "-";
      }
      tr.no-children {
        cursor: default;
      }      
}

.feature-faq {
    @import url('https://fonts.googleapis.com/css?family=Hind:300,400&display=swap');

    $bg: #fff;
    $text: #7288a2;
    $gray: #4d5974;
    $lightgray: #e5e5e5;
    $blue: #03b5d2;

    * {
    box-sizing: border-box;
    &::before, &::after {
        box-sizing: border-box;
    }
    }

    body {
    margin: 0;
    padding: 0;
    font-family: 'Hind', sans-serif;
    background: $bg;
    color: $gray;
    display: flex;
    min-height: 100vh;
    }

    .faq-container {
    margin: 0 auto;
    padding: 4rem;
    width: 48rem;

    @media (max-width: 992px) {
        padding: 2rem;
        width: 100%;
        }
    }

    .accordion {
    .accordion-item {
        border-bottom: 1px solid $lightgray;
        button[aria-expanded='true'] {
        border-bottom: 1px solid $blue;
        }
    }
    button {
        position: relative;
        display: block;
        text-align: left;
        width: 100%;
        padding: 1em 0;
        color: $text;
        font-size: 1.15rem;
        font-weight: 400;
        border: none;
        background: none;
        outline: none;
        &:hover, &:focus {
        cursor: pointer;
        color: $blue;
        &::after {
            cursor: pointer;
            color: $blue;
            border: 1px solid $blue;
        }
        }
        .accordion-title {
        padding: 1em 1.5em 1em 0;
        }
        .icon {
        display: inline-block;
        position: absolute;
        top: 18px;
        right: 0;
        width: 22px;
        height: 22px;
        border: 1px solid;
        border-radius: 22px;
        &::before {
            display: block;
            position: absolute;
            content: '';
            top: 9px;
            left: 5px;
            width: 10px;
            height: 2px;
            background: currentColor;
        }
        &::after {
            display: block;
            position: absolute;
            content: '';
            top: 5px;
            left: 9px;
            width: 2px;
            height: 10px;
            background: currentColor;
        }
        }
    }
    button[aria-expanded='true'] {
        color: $blue;
        .icon {
        &::after {
            width: 0;
        }
        }
        + .accordion-content {
        opacity: 1;
        max-height: 9em;
        transition: all 200ms linear;
        will-change: opacity, max-height;
        }
    }
    .accordion-content {
        opacity: 0;
        max-height: 0;
        overflow: hidden;
        transition: opacity 200ms linear, max-height 200ms linear;
        will-change: opacity, max-height;
        p {
        font-size: 1rem;
        font-weight: 300;
        margin: 2em 0;
        }
    }
    }
}