// Color Variables
$white: #fff;
$black: #000;
$primary-color:#f57479;
$secondary-color:#1f1f40;
$extra-color:#934ae4;
$border-color: #e5e5e5;
$black-light:#848485;
$yello:#ffc429;
$blue: #470FA8;
$heading-font:'Poppins', sans-serif;
$body-font:'Open Sans', sans-serif;