.feature-box {
    .feature-box-container {
        display: flex; /* Make the container a flexbox container */
        flex-wrap: wrap; /* Allow wrapping of children */
        padding-top: 200px; /* Adjust the value as needed */
        margin: 0;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
        border: 0;
        font: inherit;
        vertical-align: baseline;
        width: 100%;
        height: 100%;
    }
    
    .feature-box-container p {
        display: block;
        color: white;
    }
    
    // .horizontal-list {
    //     display: flex; /* Make the ul a flex container */
    //     list-style-type: none; /* Remove the default bullet points */
    //     padding: 0; /* Remove the default padding */
    //     margin: 0; /* Remove the default margin */
    //     width: 100%;
    // }

    // .horizontal-list li {
    //     flex: 1; /* Distribute space evenly among li elements */
    //     box-sizing: border-box; /* Include padding and border in element's total width and height */
    //     padding: 1px; /* Optional: Add some padding to li elements */
    // }
    
    .vertical_text {
        opacity: .75;
        transform: rotate(-90deg) scaleY(1.5); /* Adjusted scale */
        right: 6rem;
        top: 3rem;
        color: #bfbfbf;
        text-transform: uppercase;
        transform-origin: 100% 100%;
        font-size: 1.2rem; /* Adjusted font size */
        letter-spacing: 1.5rem; /* Adjusted letter spacing */
        transition-duration: 1s;
        transition-delay: 1.2s;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 100;
        margin: 0;
        padding: 0;
        text-align: right;
        background: linear-gradient(90deg, #000, #fff, #D3D3D3);
        background-repeat: no-repeat;
        background-size: 80%;
        animation: animateVertTxt 5s linear infinite;
        -webkit-background-clip: text;
        -webkit-text-fill-color: rgba(255, 255, 255, 0);
        overflow: visible;
    }
    
    .left_block {
        width: var(--featurebox-width, 100%);
        height: 37rem;
        opacity: 0.75;
        left: 0;
        top: var(--featurebox-top, 0);
        box-sizing: border-box;
        transition-duration: 1.5s;
        overflow: hidden;
        margin: 0;
        padding: 0rem 1rem;
        border: 0;
        vertical-align: baseline;
        background: #313131;
    }
    
    .heading_letter {
        opacity: 1;
        animation-name: head_letter;
        animation-duration: 0.8s;
        color: #66bbff;
        z-index: 4;
        width: auto;
        font-family: 'Roboto';
        font-style: normal;
        margin-left: -2rem;
        font-weight: 800;
        max-height: 59.5rem;
        max-width: 50rem;
        font-size: 12rem; /* Adjusted font size */
        padding: 0;
        line-height: 1;
        margin: 0;
        border: 0;
    }
    
    @keyframes head_letter {
        0% {
            transform: translateX(100%);
            opacity: 0;
        }
        50% {
            transform: translateX(100%);
            opacity: 0;
        }
        100% {
            transform: translateX(0%);
            opacity: 1;
        }
    }
    
    .content_heading {
        animation-name: content_heading_anim;
        animation-duration: 1.4s;
        font-size: 1.2rem;
        font-family: Roboto;
        font-weight: 500;
        line-height: 1.5;
        margin-bottom: 1rem;
        color: white;
        opacity: 1;
        padding-left: 0.4rem;
        letter-spacing: 0.3rem;
        transition-duration: 1s;
    }    
    
    @keyframes content_heading_anim {
        0% {
            transform: translateX(100%);
            opacity: 0;
        }
        50% {
            transform: translateX(100%);
            opacity: 0;
        }
        100% {
            transform: translateX(0%);
            opacity: 1;
        }
    }
    
    .content_data_ul {
        display: flex;
        animation-name: bg__list;
        animation-duration: 1.5s;
        margin-top: 1.0rem;
        opacity: 1;
        transition-duration: 0.5s;
    }
    
    @keyframes content_data_anim {
        0% {
            opacity: 0;
        }
        50% {
            opacity: 0;
            transform: translateY(100%);
        }
        100% {
            transform: translateY(0%);
            opacity: 1;
        }
    }
    
    .content_data_li_endingline {
        border-right: 2px solid #F2F3F4;
        display: inline-block;
        color: #F2F3F4;
        vertical-align: top;
        padding-top: 0.1rem;
        // padding-left: 0.7rem;
        // padding-right: 3rem;
        padding-bottom: 0.1rem;
        text-align: -webkit-match-parent;
    }
    
    .content_data_li_noline {
        display: inline-block;
        color: #F2F3F4;
        vertical-align: top;
        padding-top: 0rem;
        padding-left: 1rem;
        padding-right: 3rem;
        padding-bottom: 0.1rem;
        text-align: -webkit-match-parent;
    }
    
    .content_data_num {
        display: block;
        font-size: 1.5rem;
        margin-bottom: 0.5rem;
        font-family: Roboto;
        font-weight: 700;
        color: #F2F3F4;
        text-align: -webkit-match-parent;
    }
    
    .content_data_num_txt {
        font-family: Roboto;
        font-weight: 300;
        font-size: 1rem;
        line-height: 1.5;
        padding-top: 0.1rem;
        color: #66bbff;
    }
    
    .content_tag_line {
        animation-name: content_data_anim;
        animation-duration: 1.3s;
        width: 75%;
        margin-top: 1rem;
    }
    
    .content_tag_line_txt {
        font-family: Roboto;
        font-weight: 300;
        font-size: 1rem;
        line-height: 1.5;
        color: #7B7D7D;
        letter-spacing: 0.012rem;
    }
    
    @keyframes animateVertTxt {
        0% {
            background-position: -500%;
        }
        100% {
            background-position: 500%;
        }
    }
    
    .punchline {
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
        margin-left: 0.7rem;
        line-height: 0.8rem;
        margin-bottom: 0.5rem;
        line-height: 1.2rem;
        font-size: 1rem;
    }
    
    .punchline:first-of-type {    
        animation: showup 7s infinite;
    }
    
    .punchline:last-of-type {
        width: 0px;
        animation: reveal 7s infinite;
    }
    
    .punchline:last-of-type span {
        margin-left: -355px;
        animation: slidein 7s infinite;
    }
    
    @keyframes showup {
        0% {opacity: 0;}
        20% {opacity: 1;}
        80% {opacity: 1;}
        100% {opacity: 0;}
    }
    
    @keyframes slidein {
        0% { margin-left: -800px; }
        20% { margin-left: -800px; }
        35% { margin-left: 0px; }
        100% { margin-left: 0px; }
    }
    
    @keyframes reveal {
        0% {opacity: 0; width: 0px;}
        20% {opacity: 1; width: 0px;}
        30% {width: 355px;}
        80% {opacity: 1;}
        100% {opacity: 0; width: 355px;}
    }
    
    .fade {
        color: white;
        display: block;
        position: relative;
        top: 0;
        left: 50%;
        right: 50%;
        padding: 2px 0px 5px 2px;
        font-size: 1.2rem;
        line-height: 1.5;
    }
}
