.bg-primary {
	background: $primary-color !important;
}

.bg-secondary {
	background: $secondary-color !important;
}

.bg-extra {
	background: $extra-color;
}

.bg-blue{
	background: $blue !important;
}

.text-color {
	color: $primary-color;
}

.text-black {
	color: $black;
}

.section {
	padding: 120px 0px;
}

.section-sm {
	padding: 80px 0px 65px 0px;
}

.content.text-white *{
	color: $white;
}

.content-button-text-shadow {
	text-shadow : 0 1px 2px rgba(13,0,77,.08),0 2px 4px rgba(13,0,77,.08),0 4px 8px rgba(13,0,77,.08),0 6px 12px rgba(13,0,77,.06);
}


a {
	transition: all .3s ease;
	-moz-transition: all .3s ease;
	-webkit-transition: all .3s ease;
	-o-transition: all .3s ease;
}

a:hover {
	color: $primary-color !important;
}

a:hover,
a:focus {
	outline: 0;
	text-decoration: none;
}

.text-yello {
	color: $yello;
}

.text-sm-2 {
	font-size: 18px;
}

.overflow-hidden {
	overflow: hidden !important;
}

.slick-slide {
	outline: 0;

	img {
		display: inline-block;
	}
}

.image-container {
	display: flex;
	justify-content: center; /* Centers the image horizontally */
	margin-top: 150px; /* Adjust this to your needs */
}
.image-container img {
	max-width: 100%; /* Ensures the image is responsive */
	height: auto; /* Keeps the aspect ratio */
}

.svg-icon {
    width: 48px;
    height: 48px;
}

@media screen and(max-width: 992px) {
	.svg-icon {
		width: 40px;
		height: 40px;
		margin-left: -10px;
		padding-right: 10px;
	}
}

.svg-job-icon {
    width: 42px;
    height: 42px;
	margin-right: -15px;
	//padding-left: 15px;
}

@media screen and(max-width: 992px) {
	.svg-job-icon {
		width: 38px;
		height: 38px;
		margin-left: 3px;
		padding-right: 6px;
	}
}

.svg-investor-icon {
    width: 42px;
    height: 42px;
	margin-right: -14px;
	padding-right: 10px;
}

.svg-about-icon {
    width: 45px;
    height: 45px;
	margin-right: -16px;
	padding-right: 10px;
}

.svg-glossary-icon {
	width: 60px;
    height: 60px;
	margin-right: -10px;
	padding-right: 12px;
}

.svg-report-icon {
	width: 60px;
    height: 60px;
	margin-right: -10px;
	padding-right: 10px;
}

.svg-forBlog-icon {
    width: 36px;
    height: 36px;
	// margin-right: -10px;
	padding-right: 10px;
}

.svg-business-icon {
    width: 28px;
    height: 28px;
	// margin-right: -10px;
	padding-right: 10px;
}

.svg-api-icon {
    width: 45px;
    height: 45px;
	// margin-right: -10px;
	padding-right: 15px;
}

.svg-open-source-icon {
    width: 40px;
    height: 40px;
	// margin-right: -10px;
	padding-right: 10px;
}

.overlay-shape-z {
	position: absolute;
	height: 100%;
	width: 100%;
	left: 0;
	top: 0;
	z-index: -1;
}

.text-alignment {
	text-align: center;
	font-size: 16px;
	color: rgb(124, 120, 120);
}

.service-item {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
  }
  
  .service-icon {
	width: 200px; /* Adjust size as needed */
	height: 200px;
	margin-top: 10px; /* Adjust spacing as needed */
  }

