.feature-hero-gradient {

    .container {
        --max-width: 70rem;
        width: calc(100%); // Adjusted calculation
        max-width: var(--max-width); // Applied max-width separately
        margin-inline: auto;
    }

    .hero-container {
        /* Scale 0 - 1 scale */
        --effect-strength: 0.32;
        position: relative;
        isolation: isolate;
        padding: 1em;
    }

    .hero-title-container {
        padding: 250px 0px 220px 0px;
    
        h2 {
            font-size: 100px;
            line-height: 100px;
            margin-top: -70px;
        }
    
        p {
          font-size: 17px;
        }
      }

    .hero-title {
        color: #3a3a3a;
        mix-blend-mode: color-burn;
        font-family: system-ui;
        line-height: 1.1;
        font-weight: 700;
        font-size: 5rem;
        max-width: 13ch;
        margin: 0;
    }

    .hero-title[data-overlay] {
        mix-blend-mode: revert;
        position: absolute;
        left: 10;
        top: 0px;
        color: #111;
        z-index: 2;
        opacity: calc(1 - var(--effect-strength));
    }

    .canvas-container {
        position: absolute;
        z-index: -1;
        inset: 0;
        height: 58vh;
        clip-path: polygon(0 0, 100% 0, 100% 40%, 0% 100%);
    }

    canvas {
        width: 100%;
        height: 100%;
    }
}

@media screen and (max-width: (992px)) {
    .feature-hero-gradient .hero-title-container {
        position: relative;
        margin-block: 10rem;
        //margin-left: -125px;
    }

    .feature-hero-gradient .hero-title-container h2 {
        font-size: 77px;
        line-height: 100px;
        margin-top: -255px;
    }

    .canvas-container {
        position: absolute;
        z-index: -1;
        inset: 0;
        height: 90vh !important;
        clip-path: polygon(0 0, 100% 0, 297% 37%, 0% 100%) !important;
    }

    .hero-title-container {
        padding: 250px 0px 50px 0px !important;
        margin-top: 250px !important;
    
        h2 {
            font-size: 100px;
            line-height: 100px;
            margin-top: -70px;
        }
    
        p {
          font-size: 17px;
        }
      }
}