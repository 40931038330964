.img-absolute{
	left: 0px;
	content:"";
	bottom: 0px;
	margin-bottom: -90px;
	width: 70%;
}

.pr-6{
	padding-right: 4rem !important;
}
.progress-block{
	.progress {
	    height: 8px;
	    border-radius: 0px;
	    margin-bottom: 40px;
	    overflow: visible;
	   }

	   .progress-bar{
	   		background-color: $extra-color;
	   		width: 0;
	   		transition: all 3s ease-in-out;
		    top: 0;
		    left: 0;
		    height: 100%;
  			position: relative;

  			.skill-number{
				padding: 0px 10px;
				line-height: 22px;
				color: $white;
				position: absolute;
				top:-36px;
				right: 0px;
				margin-bottom: 10px;

				&:before{
					position: absolute;
					content:"";
					bottom: -4px;
					width: 0;
					height: 0;
					border-left: 8px solid transparent;
					border-right: 8px solid transparent;
					border-top: 8px solid $primary-color;
				}
			}
	   }
	   
	   
}


.skill-2-bg{
	.progress-bar{
	   		background-color: $primary-color;
	   		.skill-number{
				background: $extra-color;
				&:before{
					border-top: 8px solid $extra-color;
				}
			}
	   	}
}