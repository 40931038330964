.feature-investor-form {
    @import url("https://fonts.googleapis.com/css?family=Roboto+Slab:400,700|Spartan:400,700&display=swap");

    .investor-body-container {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 100vh;
        margin: 0;
        font-weight: 400;
        background-color: #04ABE3;
        text-rendering: optimizeLegibility;
        -webkit-tap-highlight-color: transparent;
        -webkit-font-smoothing: antialiased;
    }
    
    // .investor-form-container {
    //     max-width: 100%;
    //     margin: 0 auto;
    //     padding: 20px;
    // }

    .investor-work-with-us {
    display: grid;
    grid-template-areas: "investor-form investor-illustration";
    grid-template-columns: 2fr 1.25fr;
    max-width: 1000px;
    background: white;
    margin: 0 auto;
    }

    .investor-form {
    grid-area: investor-form;
    padding: 50px 50px;
    }

    .investor-h2 {
        display: block;
        font-size: 1.5em;
        margin-block-start: 0.83em;
        margin-block-end: 0.83em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
        unicode-bidi: isolate;
    }

    .investor-internal-button {
        appearance: auto;
        // font-style: ;
        // font-variant-ligatures: ;
        // font-variant-caps: ;
        // font-variant-numeric: ;
        // font-variant-east-asian: ;
        // font-variant-alternates: ;
        // font-variant-position: ;
        // font-weight: ;
        // font-stretch: ;
        // font-size: ;
        // font-family: ;
        // font-optical-sizing: ;
        // font-kerning: ;
        // font-feature-settings: ;
        // font-variation-settings: ;
        text-rendering: auto;
        color: buttontext;
        letter-spacing: normal;
        word-spacing: normal;
        line-height: normal;
        text-transform: none;
        text-indent: 0px;
        text-shadow: none;
        display: inline-block;
        text-align: center;
        align-items: flex-start;
        cursor: default;
        box-sizing: border-box;
        background-color: buttonface;
        margin: 0em;
        padding-block: 1px;
        padding-inline: 6px;
        border-width: 2px;
        border-style: outset;
        border-color: buttonborder;
        border-image: initial;
    }

    .investor-form .investor-h1 {
    font-size: 28px;
    margin-bottom: 100px;
    font-weight: normal;
    font-family: "Spartan", sans-serif;
    }
    .investor-form .investor-h2 {
    font-size: 28px;
    line-height: 40px;
    font-family: "Roboto Slab", serif;
    }
    .investor-form .investor-internal-form {
    margin-top: 100px;
    }
    .investor-form .investor-internal-label, .investor-form .investor-internal-input, .investor-form .investor-internal-textarea, .investor-form .investor-internal-button {
    display: block;
    outline: none;
    font-family: "Spartan", sans-serif;
    font-size: 18px;
    width: 100%;
    }
    .investor-form .investor-internal-label, .investor-form .investor-internal-input {
    border: 0;
    height: 40px;
    line-height: 40px;
    }
    .investor-form .investor-internal-input {
    border-bottom: 2px solid #000;
    margin-bottom: 40px;
    }
    .investor-form .investor-internal-textarea {
    line-height: 40px;
    border: 2px solid #000;
    margin-bottom: 20px;
    resize: none;
    padding: 5px;
    }
    .investor-form .investor-internal-input:focus, .investor-form .investor-internal-textarea:focus {
    border-color: #F74005;
    color: #F74005;
    }
    .investor-form .investor-internal-button {
    height: 50px;
    width: 150px;
    line-height: 50px;
    border: 2px solid #000;
    background: #000;
    color: #fff;
    transition: 0.2s all ease-in-out;
    }
    .investor-form .investor-internal-button:hover {
    background: #fff;
    color: #000;
    font-weight: bold;
    }

    .investor-illustration {
    padding-top: 100px;
    grid-area: investor-illustration;
    }
    .investor-illustration .investor-h1 {
    font-family: "Roboto Slab", serif;
    font-size: 28px;
    font-weight: normal;
    margin-bottom: 40px;
    transition: 0.2s all ease-in-out;
    }
    .investor-illustration .investor-h1:hover {
    text-decoration: underline;
    }
    .investor-illustration .investor-h2 {
    font-family: "Spartan", sans-serif;
    font-size: 18px;
    font-weight: normal;
    margin-bottom: 20px;
    }
    .investor-illustration img {
    width: 100%;
    }
    .investor-illustration .investor-link-rollup {
    padding: 145px 0 50px 20px;
    }
}

@media (max-width: 992px) {
    .investor-work-with-us {
        grid-template-areas:
        "investor-form"
        "investor-illustration";
        grid-template-columns: 1fr;
        max-width: 100%;
        padding: 20px;
    }
    
    .investor-form {
        padding: 20px;
    }
    
    .investor-illustration {
        padding: 20px 0;
        text-align: center;
    }
    
    .investor-form .investor-h1,
    .investor-illustration .investor-h1,
    .investor-illustration .investor-h2 {
        font-size: 24px;
    }
}
