// .footer_container,
// .footer_layout {
//     background: #303c42;
//     display: flex;
//     justify-content: space-between;
//     flex-wrap: wrap;
// }

// .footer_container div,
// .footer_layout div {
//     display: flex;
//     flex-wrap: wrap;
// }

// .footer_container {
//     display: flex;
//     flex-wrap: wrap;
//     justify-content: space-between;
// }

// .footer_containerVert {
//     display: flex;
//     flex-wrap: wrap;
//     justify-content: flex-start;
//     border-bottom: 1px solid #455a64;
//     margin: 0 -12px 24px;
// }

// .footer_footer-item-logo {
//     align-self: center;
// }

// .footer_copy-right-grids {
//     display: flex;
//     flex-direction: column;
//     flex-wrap: wrap;
// }

// .footer_same-sec {
//     background: #303c42;
// }

// .footer_section {
//     font-family: 'Montserrat', sans-serif;
//     padding: 16px;
// }

// .footer_section h3 {
//     color: #fff;
//     font-family: 'Montserrat', sans-serif;
// }

// .footer_section p {
//     display: block;
//     color: #cfd8dc;
// }

// .footer_section paper-listbox paper-item {
//     color: #cfd8dc;
//     text-decoration: none;
// }

// .footer_section paper-listbox paper-item:hover {
//     color: #fff;
// }

// .footer_section paper-listbox {
//     margin: 0;
//     padding: 0;
//     list-style: none;
//     background-color: transparent;
// }

// .footer_section paper-listbox paper-item {
//     list-style-type: none;
//     cursor: pointer;
//     line-height: 2.0em;
//     font-size: 14px;
//     display: table-footer-group;
// }

// .footer_last-sec {
//     background: #37424b;
//     padding-top: 30px;
// }

// .footer_last-sec h3 {
//     margin-left: 10px;
//     align-self: center;
// }

// .footer_social-links {
//     display: flex;
// }

.footer_container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.footer_section {
    flex: 1;
    margin: 0 10px;
    min-width: 200px; /* Adjust based on your design */
}

.footer_same-sec,
.footer_last-sec {
    background: #303c42;
}

.footer_social-links a {
    margin: 0 5px;
}