@import url("https://fonts.googleapis.com/css?family=Open+Sans|Poppins:400,500,700");

html,
body {
  width: 100%;
  height: 100%;
}
html {
  overflow-x: hidden;
}

body {
  font-family: $body-font;
  -webkit-font-smoothing: antialiased;
  font-size: 15px;
  font-weight: 400;
  line-height: 26px;
}

p {
  color: $black-light;
  font-size: 1rem;
}
ul {
  font-size: 1rem;
}
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: $heading-font;
  color: #222530;
  font-weight: 500;
}

h1,
.h1 {
  font-size: 45px;
  text-transform: none;
  text-transform: capitalize;
}

h2,
.h2 {
  font-size: 42px;
  line-height: 52px;
  text-transform: capitalize;
}

h3,
.h3 {
  font-size: 40px;
  line-height: 50px;
}

h4,
.h4 {
  font-size: 24px;
  line-height: 30px;
}

h5,
.h5 {
  font-size: 20px;
  line-height: 34px;
}

h6,
.h6 {
  font-size: 16px;
  line-height: 26px;
}

.text-sm {
  font-size: 14px;
}

// Signup button with background glow
.signup-box-btn {
  background: hsl(0, 0%, 100%);
  padding: 6px 14px;
  position: relative;
  border-radius: 8px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.01);

  &::after {
    position: absolute;
    content: "";
    top: 5px;
    left: 0;
    right: 0;
    z-index: -1;
    height: 100%;
    width: 100%;
    transform: scale(0.9) translateZ(0);
    filter: blur(15px);
    background: linear-gradient(
      to left,
      #ff5770,
      #e4428d,
      #c42da8,
      #9e16c3,
      #6501de,
      #9e16c3,
      #c42da8,
      #e4428d,
      #ff5770
    );
    background-size: 200% 200%;
    animation: animateGlow 4.25s linear infinite;
  }
}

@keyframes animateGlow {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 200% 50%;
  }
}

// Second signup

.btn-signup-container {
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
}
.btn-signup-g {
	flex: 1 1 auto;
	//margin: 25px;
	padding: 10px 10px;
	text-align: center;
	transition: 1s;
	background-size: 200% auto;
	color: white;
	text-shadow: 0px 0px 10px rgba(0,0,0,0.2);
	box-shadow: 0 0 20px #eee;
	border-radius: 5px;
	font-size: 1rem;
}
.btn-signup-g:hover {
  background-position: right center; /* change the direction of the change here */
}
.btn-signup {
  background-image: linear-gradient(
    45deg,
    #ffc800,
    #ff02f0,
    #8930fd,
    #49ccf9
  );
}